<template>
  <b-sidebar
    v-if="clientData && $can('create', 'clients')"
    id="add-new-client-sidebar"
    :visible="isAddNewClientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-client-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Client
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="observer"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(validateForm)"
          @reset.prevent="resetForm"
        >
          <!-- display name -->
          <b-form-group
            label="Client Full Name"
            label-for="display_name"
          >
            <validation-provider
              #default="{ errors }"
              name="display_name"
              rules="required"
            >
              <b-form-input
                id="display_name"
                v-model="clientData.display_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: Parent Client -->
          <b-form-group
            label="Parent Client (leave blank for none)"
            label-for="parent_clientname"
          >
            <validation-provider
              #default="{ errors }"
              name="parent_clientname"
            >
              <v-select
                v-model="clientData.parent_clientname"
                :options="clientsList"
                :reduce="val => val.value"
                :clearable="true"
                input-id="parent_clientname"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: Services -->
          <b-form-group
            label="Services"
            label-for="services"
          >
            <validation-provider
              #default="{ errors }"
              name="servics"
            >
              <b-form-checkbox
                v-model="clientData.services.guard"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Guard Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientData.services.eyetrax"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Eye Trax Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientData.services.guardtour"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Guard Tour Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientData.services.tenant"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Tenant Services
                </h5>
              </b-form-checkbox>
              <br>
              <b-form-checkbox
                v-model="clientData.services.privateinvestigation"
                class="mb-1"
                name="check-button"
                switch
                inline
              >
                <h5>
                  Private Investigation Services
                </h5>
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- Field: Status -->
          <b-form-group
            label="Status"
            label-for="is_active"
          >
            <validation-provider
              #default="{ errors }"
              name="is_active"
            >
              <v-select
                v-model="clientData.is_active"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="is_active"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="$can('create', 'clients')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
// import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

// Notification
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '../../../../libs/acl/ability'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewClientSidebarActive',
    event: 'update:is-add-new-client-sidebar-active',
  },
  props: {
    isAddNewClientSidebarActive: {
      type: Boolean,
      required: true,
    },
    clientsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      countries,
      blankClientData: {
        clientname: '',
        diaplay_name: '',
        parent_clientname: '',
        is_active: true,
        privatelabelname: '',
      },
      clientData: {
        is_active: true,
        services: { guard: false, eyetrax: false },
      },
      parentClientOptions: [],
    }
  },
  created() {
    // console.log('add client created')
    // console.log(this.clientsList)
    // this.clientsList.forEach(item => {
    //   console.log(item.parent_clientname)
    //   this.parentClientData.push({ parent_clientname: item.parent_clientname })
    // })
    console.log('ClientListAddNew Created')
    console.log(this.clientsList)
  },
  setup() {
    const statusOptions = store.state.appSettings.is_active

    return {
      statusOptions,
    }
  },
  methods: {
    validateForm() {
      if (ability.can('create', 'clients')) {
        if (typeof this.clientData.parent_clientname === 'undefined') {
          this.clientData.parent_clientname = ''
        }
        // Setting default values for other fields
        this.clientData.privatelabelname = ''
        this.clientData.notes_admin = ''
        this.clientData.notes_client = ''
        this.clientData.notes_public = ''
        // Determine Servics selection

        console.log('Form Validated - Submitting:')
        console.log(this.clientData)

        this.$refs.observer.validate().then(success => {
          if (success) {
            store.dispatch('app-client/addClient', this.clientData)
              .then(response => {
                this.clientData.clientname = response.clientname
                const nextRout = { name: 'apps-clients-edit', params: { clientname: this.clientData.clientname } }
                // Route client
                this.$router.replace(nextRout)
                  .then(() => {
                    this.$emit('update:is-add-new-client-sidebar-active', false)
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'New client created',
                        icon: 'CheckIcon',
                        variant: 'success',
                      },
                    })
                  })
              })
              .catch(error => {
                console.log(error)
                let theErrorStr = 'The following error(s) where received:\n'
                if (typeof error.response.data.errors !== 'undefined') {
                  error.response.data.errors.forEach(item => {
                    theErrorStr += `${item.message}\n`
                  })
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error adding client information.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: theErrorStr,
                  },
                })
              })
          }
        })
      }
    },
    resetForm() {
      this.clientData = JSON.parse(JSON.stringify(this.blankClientData))
      console.log(this.blankClientData)
      console.log(this.clientData)
      this.$refs.observer.reset()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
