var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.clientData && _vm.$can("create", "clients")
    ? _c("b-sidebar", {
        attrs: {
          id: "add-new-client-sidebar",
          visible: _vm.isAddNewClientSidebarActive,
          "bg-variant": "white",
          "sidebar-class": "sidebar-lg",
          shadow: "",
          backdrop: "",
          "no-header": "",
          right: ""
        },
        on: {
          hidden: _vm.resetForm,
          change: function(val) {
            return _vm.$emit("update:is-add-new-client-sidebar-active", val)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var hide = ref.hide
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                    },
                    [
                      _c("h5", { staticClass: "mb-0" }, [
                        _vm._v(" Add New Client ")
                      ]),
                      _c("feather-icon", {
                        staticClass: "ml-1 cursor-pointer",
                        attrs: { icon: "XIcon", size: "16" },
                        on: { click: hide }
                      })
                    ],
                    1
                  ),
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  staticClass: "p-2",
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    },
                                    reset: function($event) {
                                      $event.preventDefault()
                                      return _vm.resetForm($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Client Full Name",
                                        "label-for": "display_name"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "display_name",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "display_name"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .display_name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "display_name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.display_name"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label:
                                          "Parent Client (leave blank for none)",
                                        "label-for": "parent_clientname"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "parent_clientname" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      options: _vm.clientsList,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: true,
                                                      "input-id":
                                                        "parent_clientname"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .parent_clientname,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "parent_clientname",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.parent_clientname"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Services",
                                        "label-for": "services"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "servics" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services.guard,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "guard",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.guard"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Guard Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services.eyetrax,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "eyetrax",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.eyetrax"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Eye Trax Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services.guardtour,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "guardtour",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.guardtour"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Guard Tour Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services.tenant,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "tenant",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.tenant"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Tenant Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        name: "check-button",
                                                        switch: "",
                                                        inline: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.clientData
                                                            .services
                                                            .privateinvestigation,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientData
                                                              .services,
                                                            "privateinvestigation",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientData.services.privateinvestigation"
                                                      }
                                                    },
                                                    [
                                                      _c("h5", [
                                                        _vm._v(
                                                          " Private Investigation Services "
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Status",
                                        "label-for": "is_active"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "is_active" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      dir: _vm.$store.state
                                                        .appConfig.isRTL
                                                        ? "rtl"
                                                        : "ltr",
                                                      options:
                                                        _vm.statusOptions,
                                                      reduce: function(val) {
                                                        return val.value
                                                      },
                                                      clearable: false,
                                                      "input-id": "is_active"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.clientData
                                                          .is_active,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.clientData,
                                                          "is_active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "clientData.is_active"
                                                    }
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex mt-2" },
                                    [
                                      _vm.$can("create", "clients")
                                        ? _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              staticClass: "mr-2",
                                              attrs: {
                                                variant: "primary",
                                                type: "submit"
                                              }
                                            },
                                            [_vm._v(" Add ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "ripple",
                                              rawName: "v-ripple.400",
                                              value:
                                                "rgba(186, 191, 199, 0.15)",
                                              expression:
                                                "'rgba(186, 191, 199, 0.15)'",
                                              modifiers: { "400": true }
                                            }
                                          ],
                                          attrs: {
                                            type: "button",
                                            variant: "outline-secondary"
                                          },
                                          on: { click: hide }
                                        },
                                        [_vm._v(" Cancel ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ]
              }
            }
          ],
          null,
          false,
          2534812325
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }